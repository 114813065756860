import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 0, 12),
      padding: theme.spacing(8, 0, 0),
      overflow: 'visible',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(6),
      },
    },
    backgroundImage: {
      backgroundPosition: 'top center',
      backgroundPositionY: 'calc(100% - 60px)',
    },
    copyContainer: {
      backgroundColor: theme.palette.type === 'dark' ? Colors.Dark : theme.palette.background.paper,
      padding: theme.spacing(0, 6, 6),
      maxWidth: '500px',
      position: 'relative',
      top: '-60px',
      '& h2': {
        color: theme.palette.success.main,
        marginTop: 0,
        paddingTop: 0,
        lineHeight: '66px',
      },
      '& p': {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(4, 'auto', 0),
        padding: 0,
        position: 'unset',
        top: 0,
        '& h2': {
          lineHeight: '60px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& h2': {
          lineHeight: '52px',
        },
      },
    },
    smallViewImageContainer: {},
  }),
);
