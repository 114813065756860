import React from 'react';
import Layout from '../containers/Layout';
import HowItWorks from '../containers/HowItWorks';

const TermsAndConditionsPage: React.FC = () => (
  <Layout title="Solvana | How it works">
    <HowItWorks />
  </Layout>
);

export default TermsAndConditionsPage;
