import React from 'react';
import { Container } from '@material-ui/core';
import { useStyles } from './styles';
import Quote from '../../../components/Quote';

const CustomerQuote: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" className={classes.root} maxWidth="xl">
      <Quote
        withBG
        quote="(We) just had solar panels installed before the end of the year. The process was easy... Bob walked us through the process step-by-step, provided all the data, demonstrated savings. He and the owner were on site the day of install to make sure everything went smoothly. It did! Ready to start saving!!!"
        authorName="Cesar J"
        authorLocation="New York"
      />
    </Container>
  );
};

export default CustomerQuote;
