import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useStyles } from './styles';
import BackgroundImage from 'gatsby-background-image';
import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Img from 'gatsby-image';

const InstallationProcess: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMediumView = useMediaQuery(theme.breakpoints.down('md'));

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-lg-battery-solar-roof-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageData = data.image.childImageSharp.fluid;

  if (isSmallerThanMediumView) {
    return (
      <section className={classes.root}>
        <Container maxWidth="lg">
          <div className={classes.smallViewImageContainer}>
            <Img fluid={data.image.childImageSharp.fluid} />
          </div>
          <div className={classes.copyContainer}>
            <Typography variant="h2">Make your transition to Solar Energy with Confidence.</Typography>
            <Typography variant="body1">
              After you&apos;ve completed those three steps, get ready to bask in the sun because we&apos;ll take it
              from here.
            </Typography>
            <Typography variant="body1">
              With over 50 years of combined experience in the Solar industry and construction, we&apos;re happy to
              execute and manage the entire process for you.
            </Typography>
            <Typography variant="body1">
              We&apos;ll take care of engineering, permitting, and installing your solar selections. Plus, coordinating
              with your utility company to install a new meter, complete interconnections, and get your solar energy
              running.
            </Typography>
            <Typography variant="body1">
              Our team will be in touch with you within 48 hours of completing your purchase to establish the next steps
              and begin your solar energy transition.
            </Typography>
          </div>
        </Container>
      </section>
    );
  }

  return (
    <section className={classes.root}>
      <BackgroundImage Tag="section" className={classes.backgroundImage} fluid={imageData}>
        <Container maxWidth="lg">
          <div className={classes.copyContainer}>
            <Typography variant="h2">Make your transition to Solar Energy with Confidence.</Typography>
            <Typography variant="body1">
              After you&apos;ve completed those three steps, get ready to bask in the sun because we&apos;ll take it
              from here.
            </Typography>
            <Typography variant="body1">
              With over 50 years of combined experience in the Solar industry and construction, we&apos;re happy to
              execute and manage the entire process for you.
            </Typography>
            <Typography variant="body1">
              We&apos;ll take care of engineering, permitting, and installing your solar selections. Plus, coordinating
              with your utility company to install a new meter, complete interconnections, and get your solar energy
              running.
            </Typography>
            <Typography variant="body1">
              Our team will be in touch with you within 48 hours of completing your purchase to establish the next steps
              and begin your solar energy transition.
            </Typography>
          </div>
        </Container>
      </BackgroundImage>
    </section>
  );
};

export default InstallationProcess;
