import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import DoubleQuotesIcon from '../Icons/DoubleQuotesIcon';
import clsx from 'clsx';

type Props = {
  quote: string;
  authorName: string;
  authorLocation?: string;
  withBG?: boolean;
};

const Quote: React.FC<Props> = ({ quote, authorLocation, authorName, withBG }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, { [classes.background]: withBG })}>
      <DoubleQuotesIcon size="48px" />
      <hgroup>
        <Typography className={classes.quote} variant="h5">
          <i>{quote}</i>
        </Typography>
        <Typography className={classes.authorName} color="primary" variant="subtitle1">
          — {authorName}
          {authorLocation ? `, ${authorLocation}` : ''}
        </Typography>
      </hgroup>
    </div>
  );
};

export default Quote;
