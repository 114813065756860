import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Process: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" maxWidth="lg" className={classes.root}>
      <div className={classes.copyContainer}>
        <Typography variant="h2">
          Our Online 3 Step Process Gets You A Better System At A Lower Price... Faster
        </Typography>
        <Typography variant="body1">
          We&apos;re currently helping home-owners in Florida, Massachusetts, and New Jersey, and business owners across
          the United States transition to solar energy in three straight-forward steps at your pace and convenience.
        </Typography>
      </div>

      <Grid className={classes.list} container component="ul" spacing={5}>
        <Grid item md={4} sm={12} component="li">
          <div className={classes.circle}>
            <Typography variant="body1">1</Typography>
          </div>
          <Typography variant="h5">Sign up to request your free solar proposal</Typography>
          <Typography variant="body1">
            We gather satellite data and imagery coupled with our unique analysis process developed from over a decade
            in the industry to recommend the best options based on your roof and electricity usage.
          </Typography>
          <Typography variant="body1">
            Accurately discover how much you will save in electricity bills every month.
          </Typography>
          <Typography variant="body1">
            Discover the positive impact going solar would have on your home and the environment.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12} component="li">
          <div className={classes.circle}>
            <Typography variant="body1">2</Typography>
          </div>
          <Typography variant="h5">
            Confidently select the best solar equipment suited for your lifestyle and complete your purchase
          </Typography>
          <Typography variant="body1">
            After completing your Solar Praxeum course in minutes, you’ll be an expert and know which solar equipment is
            best for your home and business.
          </Typography>
          <Typography variant="body1">Your purchase is seamless through our secure online payment portal.</Typography>
          <Typography variant="body1">
            Your solar advocate will reach out to you within 48 hours of completing your purchase to begin the
            installation process.
          </Typography>
        </Grid>

        <Grid item md={4} sm={12} component="li">
          <div className={classes.circle}>
            <Typography variant="body1">3</Typography>
          </div>
          <Typography variant="h5">Say goodbye to your anxiety when it comes to making the solar transition</Typography>
          <Typography variant="body1">
            Avoid common pitfalls and gain confidence about selecting the best solar options for you using our Solar
            Praxeum™ course
          </Typography>
          <Typography variant="body1">
            Tap into your solar advocate to answer any questions about the process or solar equipment
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Process;
