import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '800px',
      padding: theme.spacing(0, 3),
      margin: theme.spacing(12, 'auto', 15),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        margin: theme.spacing(8, 'auto', 8),
      },
    },
  }),
);
