import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Hero: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-happy-family-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.innerContainer}>
          <Typography color="primary" variant="h1">
            Helping Energy Conscious People Go Solar More Easily.
          </Typography>

          <Typography variant="body1">
            You want a more sustainable form of energy for your home or business, but the solar industry doesn&apos;t
            make the transition easy. We are here to fix that.
          </Typography>
          <Typography variant="body1">So we made it our mission to make going solar as easy as possible.</Typography>
          <Typography variant="body1">
            That means you can go solar without annoying salespeople knocking at your door, hidden costs in the fine
            print, or deceived by competitors&apos; exaggerated solar benefits.
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </Container>
  );
};

export default Hero;
