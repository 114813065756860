import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& hgroup': {
        textAlign: 'center',
      },
    },
    background: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : Colors.GreyFive,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
    },
    quote: {
      color: theme.palette.type === 'dark' ? Colors.GreyFour : theme.palette.secondary.main,
      fontSize: '28px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
    },
    authorName: {
      marginTop: theme.spacing(1),
    },
  }),
);
