import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Equipment: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-panel-diagram-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.innerContainer}>
          <Typography variant="h2">Explore our Industry-Leading Solar Equipment</Typography>
          <Typography variant="body1">
            Search our wide array of sleek, future-ready Panels, Inverters, Batteries, and Adders, all customizable for
            your needs.
          </Typography>
          <Typography variant="body1">
            Our solar advocates are happy to make recommendations for your home or business, help with any questions and
            repairs that may arise through the lifetime of your solar equipment.
          </Typography>
          <Typography variant="body1">
            As you explore your solar options, know that we offer financing options to make your transition to solar
            energy not only good for the environment but good for your wallet too.
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default Equipment;
