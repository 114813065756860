import React from 'react';
import Process from './Process';
import CustomerQuote from './CustomerQuote';
import Hero from './Hero';
import InstallationProcess from './InstallationProcess';
import Equipment from './Equipment';

const HowItWorks: React.FC = () => {
  return (
    <>
      <Hero />
      <Process />
      <InstallationProcess />
      <Equipment />
      <CustomerQuote />
    </>
  );
};

export default HowItWorks;
